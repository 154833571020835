import axios from '@axios'

export default {
  methods: {
    axiosInterceptors() {
      axios.interceptors.request.use(request => {
        const bearer = localStorage['bearerToken'] || ''
        request.headers['Authorization'] = `Bearer ${bearer}`
        return request
      }, error => {
        return Promise.reject(error)
      })
      /*
      const bearer = localStorage['bearerToken'] || ''
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      */
      axios.interceptors.response.use(response => {
        return response
      }, error => {
        const { response } = error
        if (response && response.status == 401 && !response.data.status) {
          this.$store.commit('auth/UNAUTHENTICATE')
          this.$router.push({ name: 'login' })
        }
        if (response && response.status == 404) {
          this.$router.replace({name:'error-404'})
        }
        return Promise.reject(error)
      })
    }
  },
  created() {
    this.axiosInterceptors()
  }
}