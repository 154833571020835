<template>
  <router-view :key="contentKey" />
</template>

<script>
import axios from '@/mixins/axios'
import { mapState } from 'vuex'

export default {
  mixins:[axios],
  computed:{
    ...mapState({
      contentKey: state => state.app.contentKey
    })
  },
  watch:{
    $route(to){
      if(to.meta.auth) this.$store.dispatch('utils/getDraftNotification')
    }
  },
  beforeCreate(){
    this.$store.commit('auth/ASSIGN_PRIVILEGE')
    this.$store.commit('auth/ASSIGN_PROFILE')
  },
  mounted(){
    this.$store.dispatch('utils/getDraftNotification')
  }
}
</script>

<style>

</style>